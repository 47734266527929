import { template as template_330103e9c607435d8b80cd8581870369 } from "@ember/template-compiler";
const FKLabel = template_330103e9c607435d8b80cd8581870369(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
