import { template as template_a85bcbae41074d6792c30bc25e90241b } from "@ember/template-compiler";
const SidebarSectionMessage = template_a85bcbae41074d6792c30bc25e90241b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
