import { template as template_9c39a943292f44308e816292d079d5c7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9c39a943292f44308e816292d079d5c7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
